import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ComparacaoLeadsService {

  constructor(private http: HttpClient) { }

  comparar(params) {
    return this.http.post<any>(environment.environment.smartMatch + 'comparar',  params , {
        responseType: 'blob' as 'json' // Recebe o arquivo como Blob
      }).pipe(
        catchError((error) => {
          console.error('Erro na API:', error);
          return throwError(() => error); // Repassa o erro para o componente
        })
      );;
  }

}
