import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessaoService } from './sessao.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private toastr: ToastrService, private sessao: SessaoService, private router: Router, private http: HttpClient) { }

  getMenus(projetoId) {
    return this.http.get(environment.environment.nomeServiceApi + 'menus/getTreePorProjeto/'+projetoId+'.json');
  }

  getUsuarioSasPorCpf(cpf) {
    return this.http.get(environment.environment.nomeServiceSas + 'usuarios/getSelecionarCPF/'+cpf+'.json');
  }

  getUsuariosAutoComplete(term: String) {
    let params : any = { nome_cpf: term};
    return this.http.get<Usuarios>(environment.environment.nomeServiceApi + 'usuarios/getAll.json', { params: params }).pipe(map((res) => res.Usuarios));
  }

  vincularGrupoUsuario(params) {
    return this.http.post(environment.environment.nomeServiceApi + 'UsuarioGrupos/postVincular.json', params);
  }

  desvincularGrupoUsuario(params) {
    return this.http.post(environment.environment.nomeServiceApi + 'UsuarioGrupos/postDesvincular.json', params);
  }

  getId(id) {
    return this.http.get<Usuario>(environment.environment.nomeServiceApi + `usuarios/getId/${id}.json`);
  }

  get(params) {
    return this.http.get<Usuarios>(environment.environment.nomeServiceApi + 'usuarios/getAll.json', { params: params });
  }

  getUsuariosSasPossuiWebService(params) {
    return this.http.get(environment.environment.nomeServiceSas + 'usuarios/getUsuariosPossuiWebService.json', { params: params });
  }

  getWebServicesSasPorUsuario(params) {
    return this.http.get(environment.environment.nomeServiceSas + 'usuarios/getWebServicesUsuario.json', { params: params });
  }

  getWebServicesUsuarioSemPermissao(params) {
    return this.http.get(environment.environment.nomeServiceApi + 'usuarios/getWebServicesUsuarioSemPermissao.json', { params: params });
  }

    getIsAuthorized(params) {
        return this.http.get<boolean>(environment.environment.nomeServiceApi + 'metodos/getIsAuthorized.json', { params: params });
    }

  salvar(params) {
    return this.http.post(environment.environment.nomeServiceApi + 'usuarios/postSave.json', params);
  }

}

export class Usuario {
  id: number;
  nome: string;
  cpf: string;
  login: string;
  email: string;
  celular: string;
  chave_sas: string;
  usuario_ad: number;
  UnidadeOrganizacionalID: any;
  UnidadeOrganizacional: string;
  admin: number;
  usuario_grupos : any;
  usuariosWs: any;

  constructor() { }
}

export class Usuarios {
  Usuarios: any;
  TotalItems: number;

  constructor() { }
}
