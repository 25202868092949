import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {
    paramsRoute: any;
    links = [];
    constructor(public activatedRoute: ActivatedRoute, private router: Router) {
    }

    ngOnInit(): void {
        this.paramsRoute = this.activatedRoute.snapshot.params;

        this.links = [
            { title: 'Monitoramento Uptime Kuma', fragment: 'uptime-sc', url: 'uptime-kuma' },
            { title: 'Monitoramento Local', fragment: 'monLocal', url: 'dashboard' },
            { title: 'Cadastro Local', fragment: 'cadLocal', url: 'geral' },
        ];

        if(environment.environment.uf != 'SC'){
            this.links = this.links.filter(link => link.fragment != 'uptime-sc');
        } else {
            this.router.navigate(['health-check', 'uptime-kuma'], { fragment: 'uptime-sc' });
        }
    }

}
