import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Comparação de Leads'
    },
    // children: [
    //   {
    //     path: 'form/:id',
    //     component: SegmentacaoLeadsFormComponent,
    //     data: {
    //       title: 'Cadastro de Segmentação de Leads'
    //     }
    //   },
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComparacaoLeadsRoutingModule { }
