import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HealthCheckComponent} from "./health-check.component";


const routes: Routes = [
  {
    path: '',
      component: HealthCheckComponent,
    data: {
      title: 'Saúde da Aplicação'
    },
    children: [
    {
        path: 'uptime-kuma',
        loadChildren: () => import('./uptime-kuma/uptime-kuma.module').then(m => m.UptimeKumaModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./health-check-dashboard/health-check-dashboard.module').then(m => m.HealthCheckDashboardModule)
    },
    {
        path: 'geral',
        loadChildren: () => import('./health-check-geral/health-check-geral.module').then(m => m.HealthCheckGeralModule)
    }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthCheckRoutingModule { }
