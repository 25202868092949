import { KeycloakService } from "keycloak-angular";
import {
    Component,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ApplicationRef,
} from "@angular/core";
//import { navItems } from '../../_nav';
import { SessaoService } from "../../services/sessao.service";
import { Router } from "@angular/router";
import { UsuarioService } from "../../services/usuario.service";
import { environment } from "../../../environments/environment";
import { ProjetoService } from "../../services/projeto.service";
import { SseService } from "../../services/sse.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-dashboard",
    templateUrl: "default-layout.component.html",
    styleUrls: ["default-layout.component.scss"],
})
export class DefaultLayoutComponent {
    //public navItems = navItems;
    public navItems = [];
    public sidebarMinimized = false;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;
    public usuario: any;
    public search: any;
    public searchOption: any = "id";
    public searchOptionMask: any = "";
    public versao: String = "";
    public uf: String = "";
    public projetoId: "";

    constructor(
        private sessao: SessaoService,
        private router: Router,
        private usuarioService: UsuarioService,
        private projetoService: ProjetoService,
        private readonly keycloak: KeycloakService,
        private toastr: ToastrService,
        private sseService: SseService,
        private ref: ChangeDetectorRef
    ) {
        if (environment.local != true) {
            this.sseService
                .createEventSource("eventsCsv/" + this.sessao.getUsuarioId())
                .subscribe((e: any) => {
                    console.log("Message received csv: ", e);
                    this.toastr.info(e.html, e.titulo, {
                        onActivateTick: true,
                        enableHtml: true,
                        closeButton: true,
                        disableTimeOut: true,
                        toastClass: "ngx-toastr custom-toast",
                    });
                    this.ref.detectChanges();
                });
        }

        this.versao = environment.environment.versao;
        this.uf = environment.environment.uf;
        this.carregarMenu();

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized =
                document.body.classList.contains("sidebar-minimized");
        });

        this.changes.observe(<Element>this.element, {
            attributes: true,
        });

        this.usuario = this.sessao.get("Login");
    }

    logout() {
        this.sessao.limparSessao();
        if (environment.environment.autenticarPorAmei) {
            this.keycloak.logout();
        } else {
            this.router.navigate(["/login"]);
        }
    }

    selecionarMaskSearch() {
        switch (this.searchOption) {
            case "cpf":
                this.searchOptionMask = "000.000.000-00";
                break;
            case "cnpj":
                this.searchOptionMask = "00.000.000/0000-00";
                break;
            default:
                this.searchOptionMask = "";
                break;
        }
    }

    buscarLeads() {
        if (this.searchOption && this.search) {
            const filter = { [this.searchOption]: this.search };
            this.router.navigate(["/leads"], { queryParams: filter });
            this.search = "";
        }
    }

    carregarMenu() {
        if (this.sessao.get("NavItems")) {
            this.navItems = this.sessao.get("NavItems");
        } else {
            this.montarMenus();
        }
    }

    async montarMenus(): Promise<void> {
        let listMenus: any;
        //let contadores: any;
        let navItems = [];

        await this.projetoService
            .getPorNome(environment.environment.projeto)
            .then((data) => {
                this.projetoId = data["id"];
            });
        listMenus = await this.usuarioService
            .getMenus(this.projetoId)
            .toPromise();
        listMenus.forEach((m, i) => {
            let nav: any = {
                title: m.title,
                name: m.nome,
                url: m.url,
                icon: m.icone,
            };
            if (m.filhos.length > 0) {
                nav.children = [];
            }

            //Atualizar badge dinâmicamente
            if (m.badge == 1) {
                let keys = m.variant.split(" ");
                let textCustom = "";

                if (m.text) {
                    textCustom = m.text;
                }
                // else if (contadores[keys[1]]) {
                //   textCustom = contadores[keys[1]];
                // }
                nav.badge = { variant: m.variant, text: textCustom, id: m.id };
            }

            m.filhos.forEach((f) => {
                let item: any;

                item = { name: f.nome, url: f.url, icon: f.icone };

                //Atualizar badge dinâmicamente
                if (f.badge == 1) {
                    let keys = f.variant.split(" ");
                    let textCustom = "";

                    if (f.text) {
                        textCustom = f.text;
                    }
                    // else if (contadores[keys[1]]) {
                    //   textCustom = contadores[keys[1]];
                    // }
                    item.badge = {
                        variant: f.variant,
                        text: textCustom,
                        id: f.id,
                    };
                }
                nav.children.push(item);
            });

            navItems.push(nav);
        });
        //  navItems.push( {title: false, name: "Clientes", url: "/micro-cadastro-cliente", icon: "icon-speedometer"})
        this.navItems = navItems;
        this.sessao.set("NavItems", navItems);
    }

    toggleMinimize(e) {
        this.sidebarMinimized = e;
    }
}
