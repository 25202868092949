import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { environment } from '../../../../environments/environment';
import { GrupoService } from '../../../services/grupo.service';
import { CustomServiceValidateService } from '../../../shared/control-messages/custom-service-validate.service';
import {ProjetoService} from "../../../services/projeto.service";

@Component({
  selector: 'app-grupo-permissoes-form',
  templateUrl: './grupo-permissoes-form.component.html',
  styleUrls: ['./grupo-permissoes-form.component.scss']
})
export class GrupoPermissoesFormComponent implements OnInit {

  items: TreeviewItem[];
  checkPermissoes: string[];
  form: FormGroup;
  jsonTree : any = [];
  projetosInPermissoes: any = 'api,check-cadastro,email';

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
    public projetoId: '';

  constructor(private fb: FormBuilder
    , private grupoService:GrupoService
    , private projetoService: ProjetoService
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.projetosInPermissoes = `${environment.environment.projeto},${environment.environment.projetoCadCli},${this.projetosInPermissoes}`;
    await this.projetoService.getPorNome(environment.environment.projeto).then(data => {
        this.projetoId = data['id'];
    });
    this.initForm();
    const grupo_id = this.form.value.id ? this.form.value.id : (this.route.snapshot.params.id ? this.route.snapshot.params.id : '');

    this.jsonTree = await this.grupoService.getTreeviewFuncionalidadesPorProjetos({grupo_id : grupo_id, projetos_in: this.projetosInPermissoes}).toPromise();
    this.items = this.getFormatTreeview();

    if (grupo_id) {
      this.getId(grupo_id);
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      descricao: ['', Validators.required],
      ativo: [1],
      projeto_id: [this.projetoId]
    });
  }

  onFilterChange(value: string) {
    console.log('filter:', value);
  }

  getFormatTreeview(): TreeviewItem[] {
    let list : any = [];
    this.jsonTree.forEach((item) => {
      list.push(new TreeviewItem(item));
    });
    return list;
  }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    } else if(this.checkPermissoes.length <= 0){
      this.toastr.warning('Por favor selecione ao menos um item da lista de funcionalidades!', 'Atenção');
      return false;
    }
    this.form.value.ativo = this.form.value.ativo ? 1 : 0;

    let permissoes: any = []; //permissoes
    this.checkPermissoes.forEach((values) => {
      const obj = values.split(";");
      if(obj && obj[0]){
        permissoes.push({metodo_id: obj[0], id: obj[1] });
      }
    });
    this.form.value.permissoes = permissoes;

    this.grupoService.salvar(this.form.value).subscribe(response => {
      this.toastr.success('Grupo e permissões salvo com sucesso.');
      this.router.navigate(['/grupos-permissoes/alterar/' + response['id']]);
    }, e => {
      CustomServiceValidateService.customMensageService(e.error, this.form);
    });
  }

  getId(id) {
    this.grupoService.getId(id).toPromise().then(response => {
      this.form.patchValue(response);
    });
  }

  get permissoes(): FormArray {
    return this.form.get('permissoes') as FormArray;
  }

  async novo() {
    this.jsonTree = await this.grupoService.getTreeviewFuncionalidadesPorProjetos({grupo_id : '', projetos_in: this.projetosInPermissoes}).toPromise();
    this.items = this.getFormatTreeview();
    this.initForm();
  }

}
