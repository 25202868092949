<div class="animated fadeIn">

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-table"></i>
                    <strong>Comparação de Leads</strong>
                </div>
                <div class="card-body">
                    <form [formGroup]="form" (submit)="enviar()">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>*Selecione a Campanha:</label>
                                    <ng-select [items]="campanhas" placeholder="Selecione..."
                                        formControlName="campanha_id" (change)="getImportacaoes($event)"
                                        bindLabel="nome" bindValue="id" name="campanha_id">
                                    </ng-select>
                                    <app-control-messages [control]="form.controls.campanha_id"></app-control-messages>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Selecione uma Lista de Arquivo Importado:</label>
                                    <ng-select [items]="arquivosImportados" placeholder="Selecione..." bindLabel="nome"
                                        bindValue="id" formControlName="arquivo_importado" name="arquivo_importado">
                                    </ng-select>
                                    <app-control-messages
                                        [control]="form.controls.arquivo_importado"></app-control-messages>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div formGroupName="comparacoes">

                                    <!-- <div formGroupName="comparacoes">
                                        <label for="de">De:</label>
                                        <input id="de" formControlName="de" />
                                        <div
                                            *ngIf="formulario.get('comparacoes.de').invalid && formulario.get('comparacoes.de').touched">
                                            O campo "De" é obrigatório.
                                        </div>

                                        <label for="para">Para:</label>
                                        <input id="para" formControlName="para" />
                                        <div
                                            *ngIf="formulario.get('comparacoes.para').invalid && formulario.get('comparacoes.para').touched">
                                            O campo "Para" é obrigatório.
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>*Campos de Comparação:</label>
                                                <ng-select [items]="comparar" placeholder="Selecione..."
                                                    formControlName="de" name="de" [searchable]="false">
                                                </ng-select>
                                                <app-control-messages
                                                    [control]="form.get('comparacoes.de')"></app-control-messages>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="para">*Nome do Campo na Planilha:</label>
                                                <div class="input-group">
                                                    <input class="form-control" type="text" formControlName="para"
                                                        name="para">
                                                </div>
                                                <app-control-messages
                                                    [control]="form.get('comparacoes.para')"></app-control-messages>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="form.get('comparacoes.de').value == 'CPF+CNPJ'">
                                                <label for="cnpj">*Campo CNPJ na Planilha:</label>
                                                <div class="input-group">
                                                    <input class="form-control" type="text" formControlName="cnpj"
                                                        name="cnpj">
                                                </div>
                                                <app-control-messages
                                                    [control]="form.get('comparacoes.cnpj')"></app-control-messages>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>
                                        <i class="fa fa-info-circle fa-sm"
                                            tooltip="Fazer upload do arquivo para comparação."></i>
                                        *Selecione o Arquivo para Comparação
                                    </label>

                                    <div class="input-group">
                                        <input type="file" id="arquivo" (change)="onFileChange($event)" #fileInput
                                            formControlName="arquivo" name="arquivo">
                                        <p *ngIf="fileSizeError" style="color: red;">O arquivo excede o tamanho máximo
                                            de
                                            30MB.</p>


                                        <app-control-messages [control]="form.controls.arquivo.get('arquivo')"></app-control-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button type="submit" class="btn btn-success">
                                    <i class="fa fa-tasks"></i>
                                    Comparar e Baixar
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="alert alert-info">
                                    <h4>Como Funciona a Comparação de Leads?</h4>
                                    <p>Identifique de forma prática e rápida quais registros submetidos
                                        em um arquivo .csv ainda não estão cadastrados na base de leads de uma campanha
                                        ou importação específica.</p>

                                    <p>
                                        1. <strong>Upload do Arquivo:</strong> Faça o upload de um arquivo .csv com os
                                        registros a serem comparados.<br>
                                        2. <strong>Seleção de Campos:</strong> Escolha os campos que deseja usar na
                                        comparação (como CPF, e-mail ou uma combinação de CPF e CNPJ) e faça o
                                        mapeamento correspondente com os nomes específicos definidos na planilha.<br>
                                        3. <strong>Definição do Escopo:</strong> Compare com todos os leads de uma
                                        campanha ou apenas de uma importação específica.<br>
                                        4. <strong>Processamento:</strong> O sistema identifica os registros ausentes na
                                        base.<br>
                                        5. <strong>Download do Resultado:</strong> Baixe um arquivo .csv com os
                                        registros não encontrados.
                                    </p>
                                    <p>
                                        Essa funcionalidade garante rapidez, precisão e praticidade na gestão de seus
                                        leads.
                                    </p>
                                </div>


                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>


    </div>
