import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConteudoImportacaoService {

  constructor(private http: HttpClient) { }

  getAll(conteudo_publicado_id) {
    return this.http.get<any>(environment.environment.nomeServiceIntegra + '/ConteudoImportacoes/getAll/'+conteudo_publicado_id+'.json');
  }

  deleteLeads(importacao_id: number) {
    return this.http.delete(environment.environment.nomeServiceIntegra + `ConteudoImportacoes/deleteLeads/${importacao_id}.json`);
  }

}
