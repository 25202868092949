<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Pesquisa de <strong>Usuários</strong>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Nome:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="name">E-mail:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.email" name="email">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="name">Login:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.login" name="login">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Ativo:</label>
                <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                  name="ativo" [(ngModel)]="filter.ativo">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="name">CPF:</label>
                <input class="form-control" type="text" [(ngModel)]="filter.cpf" name="cpf" mask="000.000.000-00">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipos de Usuários:</label>
                <ng-select [items]="tipoUsuarios" bindLabel="nome" bindValue="id"
                  placeholder="Selecione um tipo de usuários" [(ngModel)]="filter.tipo_usuario_id"
                  name="tipo_usuario_id">
                </ng-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Usuários AD:</label>

                <ng-select [items]="listSimNao" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                  [(ngModel)]="filter.usuario_ad" name="usuario_ad">
                </ng-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Usuários Admin:</label>

                <ng-select [items]="listSimNao" bindLabel="nome" bindValue="value" placeholder="Selecione..."
                  [(ngModel)]="filter.admin" name="admin">
                </ng-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Registros por página:</label>

                <ng-select [items]="listItemsPerPage" placeholder="Selecione..." [(ngModel)]="filter.limit"
                  name="limit">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group pull-left">
                <br>
                <button type="button" class="btn btn-primary" (click)="pesquisar()">
                  <i class="fa fa-search"></i>
                  Pesquisar
                </button>
                &nbsp;&nbsp;
                <a class="btn btn-success" href="#" [routerLink]="['/usuarios/inserir']">
                  <i class="fa fa-plus"></i> Cadastrar Usuário</a>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                  <i class="fa fa-ban"></i>
                  Limpar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i>
          Resultados ({{totalItems ? totalItems : 0}})
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Código</th>
                <th>Nome / E-mail</th>
                <th>CPF</th>
                <th>Usuário</th>
                <th class="text-center">Ativo</th>
                <th class="text-center">AD</th>
                <th class="text-center">Admin</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let u of usuarios; let i = index" (click)="editarRegistro(u.id)" style="cursor: pointer;"
                tooltip="Clique para editar o cadastro" placement="left">
                <td>{{u.id}}</td>
                <td>
                  <div>{{u.nome}}</div>
                  <div class="small text-muted"><span><strong>E-mail:</strong></span> {{u.email}}</div>
                  <div class="small text-muted"><span><strong>Unidade Organizacional:</strong></span> {{u.UnidadeOrganizacional}}</div>
                </td>
                <td>{{u?.cpf | completeDigitoCpf | mask: '000.000.000-00'}}</td>
                <td>
                  <div>{{u.login}}</div>
                  <div class="small text-muted"><span><strong>Tipo:</strong></span>
                    {{u.tipo_usuario?.nome ? u.tipo_usuario?.nome : ''}}</div>
                </td>
                <td class="text-center"><span
                    [ngClass]="u.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{u.ativo
                    == 1 ? 'Sim' : 'Não'}}</span>
                </td>
                <td class="text-center"><span
                    [ngClass]="u.usuario_ad == 1 ? 'badge badge-success' : 'badge badge-danger'">{{u.usuario_ad
                    == 1 ? 'Sim' : 'Não'}}</span>
                </td>
                <td class="text-center"><span
                    [ngClass]="u.admin == 1 ? 'badge badge-success' : 'badge badge-danger'">{{u.admin
                    == 1 ? 'Sim' : 'Não'}}</span>
                </td>
              </tr>
              <tr *ngIf="usuarios && usuarios.length == 0">
                <td colspan="7">Não existe usuários com estes parâmetros</td>
              </tr>
            </tbody>
          </table>

          <div class="col-md-12 justify-content-center">
            <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
              class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
