import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../../../services/usuario.service';
import { TipoUsuarioService } from '../../../services/tipo-usuario.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {ProjetoService} from "../../../services/projeto.service";
import { UnidadeOrganizacionalService } from '../../../services/unidade-organizacional.service';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})
export class UsuarioFormComponent implements OnInit {
  form: FormGroup;
  usuarioGrupos: any;
  tipoUsuarios: any;
  unidades: any;
  infoUsuarioSas: any;
  projetoId: '';
  chave_sas_admin: '';
  listErros: Array<any> = [];

  constructor(private fb: FormBuilder
    , private usuarioService: UsuarioService
    , private TipoUsuarioService: TipoUsuarioService
    , private toastr: ToastrService
    , private router: Router
    , private route: ActivatedRoute
    , private projetoService: ProjetoService
    , private unidadeOrganizacionalService: UnidadeOrganizacionalService) {
  }

  async ngOnInit() {
    this.initForm();
    await this.projetoService.getPorNome(environment.environment.projeto).then(data => {
        this.projetoId = data['id'];
    });

    let response = await this.usuarioService.get({'login': 'admin_'+environment.environment.uf.toLocaleLowerCase()}).toPromise();
    this.chave_sas_admin = response.Usuarios && response.Usuarios[0] ? response.Usuarios[0].chave_sas : '';
    this.form.patchValue({chave_sas: this.chave_sas_admin});

    if (this.route.snapshot.params.id) {
      this.getId(this.route.snapshot.params.id);
    }
    this.getTipoUsuarios();
    this.getUnidades();
  }

  async initForm() {
    this.form = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      cpf: [''],
      celular: [''],
      login: ['', Validators.required],
      email: ['', Validators.required],
      usuario_ad: [0],
      tipo_usuario_id: [0],
      senha: [''],
      chave_sas: [this.chave_sas_admin],
      UnidadeOrganizacionalID: [''],
      UnidadeOrganizacional: [''],
      admin: [0],
      ativo: [1]
    });
  }

  salvar() {
    if (this.form.invalid) {
      Object.keys(this.form.value).forEach(campo => this.form.get(campo).markAsTouched());
      this.toastr.warning('Por favor verifique os campos obrigatórios!', 'Atenção');
      return false;
    }

    this.form.value.ativo = this.form.value.ativo ? 1 : 0;
    this.form.value.usuario_ad = this.form.value.usuario_ad ? 1 : 0;
    this.form.value.admin = this.form.value.admin ? 1 : 0;

    let unidades = this.unidades.filter((item: any) => item.CodigoUnidadeOrganizacional === parseInt(this.form.value.UnidadeOrganizacionalID));
    this.form.value.UnidadeOrganizacional = unidades[0] && unidades[0].Descricao ? unidades[0].Descricao : null;

    this.usuarioService.salvar(this.form.value).subscribe(response => {
      this.listErros = [];
      this.toastr.success('Usuário salvo com sucesso.');
      this.router.navigate(['/usuarios/alterar/' + response['id']]);
    }, e => {
      this.listErros = e.error;
    });
  }

  getId(id) {
    this.usuarioService.getId(id).toPromise().then(async response => {
      if(response.cpf){
        response.cpf = ("0000" + response.cpf).slice(-11);
      }
      response.UnidadeOrganizacionalID = response.UnidadeOrganizacionalID ? parseInt(response.UnidadeOrganizacionalID) : null;
      this.usuarioGrupos = response.usuario_grupos;
      this.form.patchValue(response);
      this.carregarDadosChaveSas();
    });
  }

  async carregarDadosChaveSas(){
    this.infoUsuarioSas = null;
    let chave_sas_base64 = btoa(this.form.value.chave_sas);
    if(chave_sas_base64){
      this.toastr.info('Carregando dados do usuário desta chave SAS', 'Aguarde');
      let usuario = await this.usuarioService.getUsuariosSasPossuiWebService({ChaveBase64: chave_sas_base64}).toPromise();
      if(usuario && usuario[0]){
        let userSas = usuario[0];
        if (userSas.CgcCpf) {
          userSas.CgcCpf = ("0000" + userSas.CgcCpf).slice(-11);//tratar para formatar corretamente no html
        }
        this.infoUsuarioSas = userSas;

        this.infoUsuarioSas.webservices = await this.usuarioService.getWebServicesSasPorUsuario({ ChaveBase64: chave_sas_base64 }).toPromise();
        this.infoUsuarioSas.wsSemPermissoes = await this.usuarioService.getWebServicesUsuarioSemPermissao({ projeto_id: this.projetoId, ChaveBase64: chave_sas_base64 }).toPromise();
        this.toastr.clear();
      } else {
        this.toastr.warning('Não encontrado informações desta chave SAS, se a mesma foi criada hoje, aguarde até amanhã e verifique novamente.', 'Atenção');
      }
    }
  }

  getTipoUsuarios() {
    this.TipoUsuarioService.get().subscribe(response => {
      this.tipoUsuarios = response;
    });
  }

  getUnidades() {
    this.unidadeOrganizacionalService.getUnidades().subscribe(response => {
      this.unidades = response;
    });
  }

  novo() {
    this.infoUsuarioSas = null;
    this.initForm();
  }

}
